<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        class="mr-2"
        min-width="36px"
        max-width="36px"
        v-on="on"
      >
        <v-icon> mdi-content-duplicate </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="createPtd">
        <v-icon>mdi-plus</v-icon> Создать ПТД
      </v-list-item>
      <v-divider v-if="list.length" />
      <v-list-item
        v-for="(item, index) in list"
        :key="index"
        @click="openPtd(item.id)"
      >
        <v-icon>mdi-open-in-new</v-icon> <span class="px-1">{{ item.id }}</span> <span v-if="item.out_number">«{{ item.out_number }}»</span>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { PTD } from "@/http-routes";

export default {
  data(){
    return{
      list:[]
    }
  },
  computed: {
    ...mapGetters({
      selected: "rs/getSelected",
    }),
  },
  watch: {
    "selected.id": {
      handler() {
        this.getListByDeclarationId();
      },
    },
  },
  methods: {
    getListByDeclarationId() {
      return axios.get(`${PTD}declaration/getByDeclarationId?declarationId=${this.selected.id}`)
        .then(({data}) => {
          this.list = data
          this.list.sort((a, b) => b.id - a.id)
        })
    },
    openPtd(id) {
      const route = this.$router.resolve({
        path: `/ptd/${id}`,
      });
      window.open(route.href, "_blank");
    },
    createPtd(id) {
      this.$store
        .dispatch("rs/createPtd", id)
        .then(({ data }) => this.openPtd(data))
        .then(() => this.getListByDeclarationId())
        .catch(() => this.$error());
    }
  },
};
</script>

<style scoped>
.treeview {
  background-color: #fff;
  cursor: pointer;
}
</style>
